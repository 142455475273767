.avatar {
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
}

.small {
    width: 24px;
    height: 24px;
}

.medium {
    width: 48px;
    height: 48px;
}

.large {
    width: 200px;
    height: 200px;
}
