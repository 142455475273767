/* Grid.module.scss */
.container {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.item {
    padding: 8px;
}

/* Adjust container margins based on spacing */
@for $i from 0 through 5 {
    .spacing-#{$i} {
        margin: -$i * 4px;

        >.item {
            padding: $i * 4px;
        }
    }

    .rowSpacing-#{$i} {
        margin-top: $i * 4px;
        margin-bottom: $i * 4px;

        >.item {
            padding-top: $i * 4px;
            padding-bottom: $i * 4px;
        }
    }

    .columnSpacing-#{$i} {
        margin-left: $i * 4px;
        margin-right: $i * 4px;

        >.item {
            margin-left: $i * 4px;
            margin-right: $i * 4px;
        }
    }
}

/* Flex Direction */
.direction-row {
    flex-direction: row;
}

.direction-column {
    flex-direction: column;
}

/* Alignment */
.alignItems-flex-start {
    align-items: flex-start;
}

.alignItems-center {
    align-items: center;
}

.alignItems-flex-end {
    align-items: flex-end;
}

.alignItems-stretch {
    align-items: stretch;
}

.alignItems-baseline {
    align-items: baseline;
}

/* Justify Content */
.justifyContent-flex-start {
    justify-content: flex-start;
}

.justifyContent-center {
    justify-content: center;
}

.justifyContent-flex-end {
    justify-content: flex-end;
}

.justifyContent-space-between {
    justify-content: space-between;
}

.justifyContent-space-around {
    justify-content: space-around;
}

.justifyContent-space-evenly {
    justify-content: space-evenly;
}

/* Responsive Grid */
.xs-true {
    flex-grow: 1;
    flex-basis: 0;
    max-width: "100%";
}

/* Define breakpoints */
$breakpoints: (
    "xs": 0,
    "sm": 600px,
    // Small devices (tablets, 600px and up)
    "md": 960px,
    // Medium devices (desktops, 960px and up)
    "lg": 1280px,
    // Large devices (large desktops, 1280px and up)
    "xl": 1920px // Extra large devices (extra-large desktops, 1920px and up)
);

/* Iterate over each breakpoint */
@each $breakpoint, $min-width in $breakpoints {

    /* Create responsive classes for each breakpoint */
    @if $breakpoint =="xs" {

        /* Extra small devices don't need a media query */
        @for $i from 1 through 12 {
            .#{$breakpoint}-#{$i} {
                flex-basis: $i / 12 * 100%;
                max-width: $i / 12 * 100%;
            }
        }
    }

    @else {

        /* Other sizes use media queries */
        @media (min-width: $min-width) {
            @for $i from 1 through 12 {
                .#{$breakpoint}-#{$i} {
                    flex-basis: $i / 12 * 100%;
                    max-width: $i / 12 * 100%;
                }
            }
        }
    }
}

.display-flex {
    display: flex;
}